import React, { useState, useEffect, useRef } from "react";

const ErrorMessage = ({ message, timeout = 10000, onDismiss }) => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutsRef = useRef([]);

  useEffect(() => {
    if (message) {
      setIsVisible(true);

      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, timeout);

      timeoutsRef.current.push(timeoutId);
    }

    return () => {
      timeoutsRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
      timeoutsRef.current = [];
    };
  }, [message, timeout]);

  return isVisible ? (
    <div className={"fade-animation error-message"}>
      <span>{message}</span>
    </div>
  ) : null;
};

export default ErrorMessage;
