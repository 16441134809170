import { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { TranslationContext } from "../../Components/TranslationContext";

import {
  getToken,
  fetchDataFromAPI,
  postData,
  getMessage,
  formatDateString,
  useErrorHandler,
} from "../../utils/hooks";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import Login from "../Login";
import * as constants from "../../utils/constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from "@mui/material";

function RentChargesAdjustment() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);
  const { translations } = useContext(TranslationContext);
  const { contract_id } = useParams();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAdjusted, setTotalAdjusted] = useState(0);
  const [ccySymbol, setCcySymbol] = useState("");
  const [adjustmentInfo, setAdjustmentInfo] = useState("");
  const [rentProperty, setRentProperty] = useState("");
  const [formValues, setFormValues] = useState({});
  const [displayedLabels, setDisplayedLabels] = useState({});
  const [displayedDates, setDisplayedDates] = useState({});
  const [displayedProvs, setDisplayedProvs] = useState({});
  const [displayedCcies, setDisplayedCcies] = useState({});
  const [additionalWarning, setAdditionalWarning] = useState("");

  const fetchApiData = async () => {
    try {
      await fetchDataFromAPI(API_URL_READ, requestOptions, setData, false);
      setLoading(false);
    } catch (error) {
      handleAPIError(error);
    }
  };

  const resetForm = () => {
    if (data && data.charges) {
      const updatedFormValues = data.charges.reduce(
        (accumulator, currentValue) => {
          const idKey = `real_${currentValue.id}`;
          return {
            ...accumulator,
            [idKey]: 0,
          };
        },
        {}
      );
      setFormValues(updatedFormValues);
    }

    textFieldsRefs.current = {};
  };

  useEffect(() => {
    fetchApiData(); // read data
  }, []);

  useEffect(() => {
    if (data && data.charges) {
      setTotalAmount(data.total);
      setCcySymbol(data.ccy_symbol);
      setRentProperty(data.rent_property);
      if (data && data.charges) {
        const dsplabs = [];
        const dspdates = [];
        const dspprovs = [];
        data.charges.forEach((c) => {
          const key = `real_${c.id}`;
          dsplabs[key] = c.name;
          dspdates[key] = formatDateString(c.date);
          dspprovs[key] = c.prov;
        });
        setDisplayedLabels(dsplabs);
        setDisplayedDates(dspdates);
        setDisplayedProvs(dspprovs);
      }
      resetForm();
      setTotalAdjusted(0);
      setAdjustmentInfo("");
    }
  }, [data]);

  useEffect(() => {
    updateAdjustmentInfo(); //update summarized information
  }, [totalAdjusted]);

  useEffect(() => {
    const sum = Object.values(formValues).reduce((acc, val) => acc + val, 0);
    setTotalAdjusted(sum);
  }, [formValues]);

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
  };

  /**
   * Update selected elements and close confirmation dialog
   */
  const handleConfirmDialog = () => {
    setMessage("");
    const formToSend = {
      cid: contract_id,
      user_id: localStorage.getItem("userId"),
      real: [],
    };

    // browse all form values
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key) && key.startsWith("real_")) {
        const index = key.split("_")[1];
        const value = formValues[key];
        const provisionned = data.charges.find(
          (item) => item.id === parseInt(index)
        );
        const toSet = value - provisionned.prov;
        formToSend.real.push({ [index]: toSet });
      }
    }
    postData(API_URL, formToSend, getToken())
      .then((response) => {
        fetchApiData();
      })
      .then(() => {
        setMessage(translations.update_done);
      })
      .catch((error) => {
        handleAPIError(error);
      });
    setOpen(false);
    resetForm();
  };

  const API_URL = `${constants.API_URL}rent/charge`;
  const API_URL_READ = `${constants.API_URL}rent/charge/${contract_id}`;

  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    // Replace all non-numeric and non-decimal point characters by nothing
    updatedValue = updatedValue.replace(/[^0-9.]/g, "");

    // Ensure that there is only one decimal point
    const parts = updatedValue.split(".");
    if (parts.length > 2) {
      updatedValue = parts.slice(0, 2).join("") + "." + parts.slice(2).join("");
    }

    // Ensure that there are at most two digits after the decimal point
    const decimalIndex = updatedValue.indexOf(".");
    if (decimalIndex !== -1 && updatedValue.length - decimalIndex > 3) {
      updatedValue = updatedValue.slice(0, decimalIndex + 3);
    }
    // Update the input value with the cleaned value
    //event.target.value = updatedValue;

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: parseFloat(updatedValue) || 0,
    }));

    // update total adjusted
    const inputElements = document.querySelectorAll("input.inputNumber");
    let total = 0;
    inputElements.forEach((inputElement) => {
      const value = parseFloat(inputElement.value) || 0;
      total += value;
    });

    setTotalAdjusted(total);
  };

  const updateAdjustmentInfo = () => {
    const diff = totalAmount - totalAdjusted;
    let textToDisplay =
      diff === 0
        ? translations.charges_adjustment_info_equal
        : diff < 0
        ? translations.charges_adjustment_info_more
        : translations.charges_adjustment_info_less;
    textToDisplay = textToDisplay.replace("%s1", Math.abs(diff));
    textToDisplay = textToDisplay.replace("%s2", ccySymbol);
    setAdjustmentInfo(textToDisplay);
  };

  const confirm = () => {
    //check if at list one value is empty
    let addWarningMessage = false;
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key) && key.startsWith("real_")) {
        if (formValues[key] === 0) {
          addWarningMessage = true;
        }
      }
    }
    setAdditionalWarning(
      addWarningMessage
        ? translations.charges_adjustment_confirmation_warning
        : ""
    );
    setOpen(true);
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div style={{ width: "100%" }}>
      <h1>
        {translations.charges_adjustment}&nbsp;&nbsp;&nbsp;<i>{rentProperty}</i>
      </h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p className="error">{getMessage(error)}</p>
        )
      ) : (
        <>
          <div className="chargesAdjustment">
            <Grid
              container
              spacing={2}
              direction="column"
              className="chargesAdjustmentGrid"
              key="main_adjustment_container"
            >
              <Grid
                container
                spacing={2}
                direction="row"
                className="chargesAdjustmentTitle"
                key="main_adjustment_container_title"
              >
                <Grid item xs={3} key="head_date">
                  {translations.last_adjustment}
                </Grid>
                <Grid item xs={3} key="head_name">
                  {translations.charge}
                </Grid>
                <Grid item xs={3} key="head_prov" className="right">
                  {translations.provision}
                </Grid>
                <Grid item xs={3} key="head_adj" className="right">
                  {translations.real_amount}
                </Grid>
              </Grid>
              {Object.entries(formValues).map(([key, value]) => {
                if (key.substring(0, 5) === "real_") {
                  const labelKey = "label_" + key;
                  const dateKey = "date_" + key;
                  const provKey = "prov_" + key;
                  const lineContainerKey = "lc_" + key;
                  return (
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      className="chargesAdjustmentLine"
                      alignItems="center"
                      key={lineContainerKey}
                    >
                      <Grid item xs={3} key={dateKey}>
                        {displayedDates[key]}
                      </Grid>
                      <Grid item xs={3} key={labelKey}>
                        {displayedLabels[key]}
                      </Grid>
                      <Grid item xs={3} key={provKey} className="right">
                        {displayedProvs[key]}&nbsp;{ccySymbol}
                      </Grid>
                      <Grid item xs={3} key={key} className="right">
                        <TextField
                          label={displayedLabels[key]}
                          inputRef={(ref) =>
                            (textFieldsRefs.current[key] = ref)
                          }
                          value={value}
                          onChange={handleTextFieldChange(key)}
                        />
                      </Grid>
                    </Grid>
                  );
                }
              })}
              <Grid
                container
                spacing={2}
                direction="row"
                className="chargesAdjustmentGrid"
                key="main_adjustment_container_footer"
              >
                <Grid item xs={3} key="footer_date">
                  &nbsp;
                </Grid>
                <Grid item xs={3} key="footer_name">
                  {translations.total}
                </Grid>
                <Grid item xs={3} key="footer_prov" className="right">
                  {totalAmount}&nbsp;{ccySymbol}
                </Grid>
                <Grid item xs={3} key="footer_adj" className="right">
                  {totalAdjusted}&nbsp;{ccySymbol}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className="flex flexHorizontal flexSpaceBetween chargesAdjustmentFooter">
            <div>{adjustmentInfo}</div>
            <div>
              <Button
                onClick={confirm}
                variant="outlined"
                color="primary"
                disabled={totalAdjusted === 0}
              >
                {translations.confirm}
              </Button>
            </div>
          </div>
        </>
      )}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {translations.charges_adjustment_confirmation_title}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item key={"msg1"}>
              {translations.charges_adjustment_confirmation_messsage}
            </Grid>
            <Grid item key="wmessage">
              <div className="warningDialogMessage">
                <p>{additionalWarning}</p>
                {translations.action_cant_be_undone}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleConfirmDialog}>{translations.confirm}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RentChargesAdjustment;
