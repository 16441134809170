import { useState, useEffect, useContext } from "react";
import {
  getToken,
  fetchDataFromAPI,
  getGridTexts,
  useErrorHandler,
} from "../../../utils/hooks";
import Message from "../../../Components/Message";
import ErrorMessage from "../../../Components/ErrorMessage";
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../Login";
import { DataGrid } from "@mui/x-data-grid";
import * as constants from "../../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import { TranslationContext } from "../../../Components/TranslationContext";

function Users() {
  const navigate = useNavigate();
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [accesses, setAccesses] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [message, setMessage] = useState(null);
  const { translations } = useContext(TranslationContext);

  const dataGridTexts = getGridTexts();
  const [pageSize, setPageSize] = useState(20);
  const { user_id } = useParams();
  const fetchApiData = async () => {
    try {
      //read properties
      await fetchDataFromAPI(API_URL, requestOptions, setData, false);
    } catch (error) {
      console.log(error);
      handleAPIError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("userAdmin") === "true") {
      fetchApiData(); // Appel initial pour récupérer les données
    } else {
      navigate("/NotFound");
    }
  }, []);

  useEffect(() => {
    if (data !== null && typeof data !== "undefined") {
      setPageTitle(
        translations.access_attempts +
          " " +
          data.user.first_name +
          " " +
          data.user.last_name +
          " (" +
          data.user.username +
          ")"
      );
      setAccesses(data.access_attempts);
    }
  }, [data]);

  const getRowClassName = (params) => {
    if (params.row.status === "KO") {
      return "datagridRedLine";
    }
    return "";
  };

  const API_URL = `${constants.API_URL}access_attempts/${user_id}`;

  const columns = [
    {
      field: "timestamp",
      headerName: translations.date,
      flex: 0.8,
      editable: false,
      //headerClassName: "defaultFont",
    },
    {
      field: "action",
      headerName: translations.action,
      //width: "400",
      flex: 1,
      editable: false,
      //headerClassName: "defaultFont",
    },
    {
      field: "status",
      headerName: translations.status,
      flex: 0.5,
      editable: false,
      //headerClassName: "defaultFont",
    },
    {
      field: "ip",
      headerName: translations.ip_address,
      flex: 1,
      editable: false,
      //headerClassName: "defaultFont",
    },
    {
      field: "comment",
      headerName: translations.details,
      flex: 1,
      editable: false,
      //headerClassName: "defaultFont",
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>{pageTitle}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p>Error: {error.message}</p>
        )
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              getRowClassName={getRowClassName}
              rows={accesses}
              getRowId={(row) => row.id}
              columns={columns}
              editMode="cell"
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 50]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Users;
