import React from "react";
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
const LanguageSelect = ({
  id,
  value,
  onChange,
  error,
  helperText,
  label,
  languages,
  translations,
}) => {
  return (
    <FormControl component="fieldset">
      <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
      <Select id={id} value={value} onChange={onChange} error={!!error}>
        <MenuItem key="default" value="">
          {translations.select_an_option}
        </MenuItem>
        {languages.map((languageCode) => (
          <MenuItem key={id + languageCode} value={languageCode}>
            {translations[`language_${languageCode.substring(0, 2)}`]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default LanguageSelect;
