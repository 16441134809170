import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const YearSelector = ({ years, onSelectedYearChange }) => {
  const [selectedYear, setSelectedYear] = useState(
    years.length > 0 ? years[0] : ""
  );

  const handleChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    onSelectedYearChange(year);
  };

  return (
    <>
      <Select
        value={selectedYear}
        onChange={handleChange}
        inputProps={{
          name: "year",
          id: "year-selector",
        }}
      >
        {years.map((year) => (
          <MenuItem value={year} key={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
