import { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { TranslationContext } from "../../Components/TranslationContext";
import { YearSelector } from "../../Components/YearSelector";

import {
  getToken,
  fetchDataFromAPI,
  postData,
  getMessage,
  formatDateString,
  useErrorHandler,
} from "../../utils/hooks";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import Login from "../Login";
import * as constants from "../../utils/constants";
import { Grid, TextField, Box } from "@mui/material";

function RentAndChargesSummary() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedYear, setSelectedYear] = useState("");
  const { translations } = useContext(TranslationContext);

  const fetchApiData = async () => {
    try {
      const url = API_URL + selectedYear;
      await fetchDataFromAPI(url, requestOptions, setData, false);
      setLoading(false);
    } catch (error) {
      handleAPIError(error);
    }
  };

  const fetchApiYears = async () => {
    try {
      await fetchDataFromAPI(API_URL_YEARS, requestOptions, setYears, false);
    } catch (error) {
      handleAPIError(error);
    }
  };

  useEffect(() => {
    fetchApiYears(); //read years
  }, []);

  useEffect(() => {
    if (years.length > 0) {
      setSelectedYear(years[0]);
    }
  }, [years]);

  useEffect(() => {
    if (selectedYear !== "") {
      fetchApiData(); // read data
    }
  }, [selectedYear]);

  const API_URL = `${constants.API_URL}rent/summary/`;
  const API_URL_YEARS = `${constants.API_URL}rent/years`;

  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div style={{ width: "100%" }}>
      <h1>{translations.annual_summary_title}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p className="error">{getMessage(error)}</p>
        )
      ) : (
        <>
          <div className="summary">
            <div>
              <YearSelector
                years={years}
                onSelectedYearChange={handleYearChange}
              />
            </div>
            <Grid
              container
              spacing={2}
              direction="column"
              className="summaryGrid"
              key="main_adjustment_container"
            >
              <Grid
                container
                spacing={2}
                direction="row"
                className="summaryTitle"
                key="main_adjustment_container_title"
              >
                <Grid item xs={3} key="head_properties">
                  {translations.rent_properties}
                </Grid>
                <Grid item xs={2} key="head_paid_rents" className="right">
                  {translations.annual_summary_title_paid_rents}
                </Grid>
                <Grid item xs={2} key="head_unpaid_rents" className="right">
                  {translations.annual_summary_title_unpaid_rents}
                </Grid>
                <Grid item xs={1} key="blank2">
                  &nbsp;
                </Grid>
                <Grid item xs={2} key="head_prov_charges" className="right">
                  {translations.annual_summary_title_provisioned_charges}
                </Grid>
                <Grid item xs={2} key="head_prov_adj" className="right">
                  {translations.annual_summary_title_adjusted_charges}
                </Grid>
              </Grid>
              {Object.keys(data).map((key) => (
                <>
                  {data[key].map((el) => (
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      className="summaryLine"
                      alignItems="center"
                      key={"cont_" + key + el.ccy}
                    >
                      <Grid item xs={3} key={key + el.ccy}>
                        {key}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        key={key + el.ccy + "pr"}
                        className="right"
                      >
                        {el.paidRents}&nbsp;{el.ccySymbol}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        key={key + el.ccy + "ur"}
                        className="right"
                      >
                        {el.unpaidRents}&nbsp;{el.ccySymbol}
                      </Grid>
                      <Grid item xs={1} key={key + el.ccy + "bl"}></Grid>
                      <Grid
                        item
                        xs={2}
                        key={key + el.ccy + "pc"}
                        className="right"
                      >
                        {el.provisonedCharges}&nbsp;{el.ccySymbol}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        key={key + el.ccy + "ac"}
                        className="right"
                        title={translations.annual_summary_title_adjusted_charges_desc
                          .replace(
                            "%s1",
                            el.provisonedCharges + el.adjustedCharges
                          )
                          .replace("%s2", el.ccySymbol)}
                      >
                        {el.adjustedCharges}&nbsp;{el.ccySymbol}
                      </Grid>
                    </Grid>
                  ))}
                </>
              ))}
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default RentAndChargesSummary;
