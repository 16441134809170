import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef, useContext } from "react";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
  TextField,
  InputLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from "@mui/material";
import * as constants from "../../utils/constants";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import {
  fetchTranslations,
  useErrorHandler,
  postData,
  isEmailValid,
} from "../../utils/hooks";
import { TranslationContext } from "../../Components/TranslationContext";

function Login({ setToken }) {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const textFieldsRefs = useRef({});
  const [messageType, setMessageType] = useState("error");
  const [timeOutDuration, setTimeOutDuration] = useState(30000);
  const [timeOutError, setTimeOutError] = useState(5000);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const msg = searchParams.get("msg");
  const [showCustormerWebsiteLink, setShowCustormerWebsiteLink] =
    useState(false);
  const { translations, updateTranslations, wizard, setWizard } =
    useContext(TranslationContext);
  const [formResetPasswordValues, setFormResetPasswordValues] = useState({
    email: "",
  });

  const wizardPageToGo = [
    "",
    "Appartments",
    "Tenants",
    "Contracts",
    "Contracts",
    "Rents",
  ];
  const API_URL_RESET = `${constants.API_URL}auth/reset/request`;
  const API_URL = `${constants.API_URL}auth/login`;

  const [formResetPasswordErrors, setFormResetPasswordErrors] = useState({
    email: "",
  });
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  //const { errorMessage, setErrorMessage, handleAPIError } = useErrorHandler();
  const { errorMessage, handleAPIError } = useErrorHandler();

  /*
  const handleDismissError = () => {
    setErrorMessage(null);
  };
  */

  useEffect(() => {
    if (msg !== "" && msg !== null) {
      setMessageType("info");
      setMessage(msg);
    }
  }, []);

  const resetFormResetPassword = () => {
    setFormResetPasswordValues({
      email: "",
    });
    setFormResetPasswordErrors({});
  };

  const handleResetTextFieldChange = (key) => (event) => {
    setFormResetPasswordValues((prevValues) => ({
      ...prevValues,
      [key]: event.target.value,
    }));
  };

  const handleLoginTextFieldChange = (key) => (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!isEmailValid(formValues.username)) {
      setMessage(translations.invalid_email);
      setTimeOutDuration(timeOutDuration + 1);
      return;
    }

    postData(API_URL, formValues)
      .then((data) => {
        if (data !== undefined) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("userId", data.userid);
          localStorage.setItem("userLanguage", data.lang);
          localStorage.setItem("userAdmin", data.is_admin);
          localStorage.setItem("valid_until", data.valid_until);
          localStorage.setItem("wizard", data.wizard);
          setWizard(data.wizard);
          setToken(data.token);
          async function loadTranslations() {
            try {
              if (typeof data.lang !== "undefined" && data.lang !== "") {
                const translationsData = await fetchTranslations(data.lang);
                updateTranslations(translationsData);
              }
            } catch (error) {
              handleAPIError(error);
            }
          }
          loadTranslations();
          if (data.is_admin) {
            navigate("/AdminUsers");
          } else if (data.user_complete) {
            if (data.wizard < wizardPageToGo.length) {
              navigate("/" + wizardPageToGo[data.wizard]);
            } else {
              navigate("/Rents");
            }
          } else {
            navigate("/User");
          }
        } else {
          setToken(null);
          throw new Error(translations.authentication_failed);
        }
      })
      .catch((error) => {
        console.log(error);
        setTimeOutError(timeOutError + 1);
        handleAPIError(error);
        if (typeof error.response.data.reason !== "undefined") {
          setShowCustormerWebsiteLink(true);
        }
      });
    setOpenResetPassword(false);
    resetFormResetPassword();
  };

  const validateResetPasswordForm = () => {
    let isValid = true;
    const errors = {
      email: "",
    };

    /**
     * All fields are mandatory except stair
     */

    if (!isEmailValid(formResetPasswordValues.email)) {
      errors["email"] = translations.invalid_email;
      isValid = false;
    }
    setFormResetPasswordErrors(errors);
    return isValid;
  };

  /**
   * Close Reset password dialog
   */
  const handleOpenResetPasswordDialog = () => {
    setOpenResetPassword(true);
  };

  /**
   * Close Reset password dialog
   */
  const handleCloseResetPasswordDialog = () => {
    setOpenResetPassword(false);
    resetFormResetPassword();
  };
  /**
   * Confirm reset password
   */
  const handleConfirmResetPasswordDialog = () => {
    if (validateResetPasswordForm()) {
      postData(API_URL_RESET, formResetPasswordValues)
        .then((response) => {
          setMessage(response);
        })
        .catch((error) => {
          handleAPIError(error);
        });
      setOpenResetPassword(false);
      resetFormResetPassword();
    }
  };

  return (
    <>
      <h1>{translations.identification}</h1>
      {errorMessage && (
        <ErrorMessage
          message={errorMessage}
          timeout={timeOutError}
          //onDismiss={handleDismissError}
        />
      )}
      <div className="login-wrapper">
        <div>
          <form onSubmit={handleSubmit}>
            <label>
              <p>{translations.email}</p>
              <input
                id="email"
                type="text"
                value={formValues.username}
                onChange={handleLoginTextFieldChange("username")}
              />
            </label>
            <label>
              <p>{translations.password}</p>
              <input
                id="password"
                type="password"
                value={formValues.password}
                onChange={handleLoginTextFieldChange("password")}
              />
            </label>
            <div className="flex flexHorizontalCenter">
              <Button type="submit" variant="contained" color="primary">
                {translations.validate}
              </Button>
            </div>
          </form>
          <br></br>
          <div className="flex flexHorizontalCenter createAccountButton">
            {/* Ce lien devra disparaître quand le site sera devenu payant*/}
            <Link component={RouterLink} to="/Signin">
              {translations.create_account}
            </Link>
          </div>
          <div className="flex flexHorizontalCenter">
            <Link
              visibility={!showCustormerWebsiteLink ? "visible" : "hidden"}
              component="button"
              onClick={() => {
                handleOpenResetPasswordDialog();
              }}
            >
              {translations.lost_password}
            </Link>
          </div>
          <div className="flex flexHorizontalCenter">
            <Link
              href="https://locabel.fr"
              target="_blank"
              visibility={showCustormerWebsiteLink ? "visible" : "hidden"}
            >
              {translations.renew_subscription}
            </Link>
          </div>
        </div>
        <Message
          message={message}
          timeout={timeOutDuration}
          type={messageType}
        />
      </div>
      <Dialog open={openResetPassword}>
        <DialogTitle>
          <SentimentVeryDissatisfiedIcon></SentimentVeryDissatisfiedIcon>&nbsp;
          {translations.lost_password}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item key={"email"}>
              <InputLabel id="reset_info" style={{ whiteSpace: "pre-line" }}>
                {translations.lost_password_info}
              </InputLabel>
              <br></br>
              <TextField
                label={translations.email}
                inputRef={(ref) => (textFieldsRefs.current["email"] = ref)}
                value={formResetPasswordValues.email}
                onChange={handleResetTextFieldChange("email")}
                error={!!formResetPasswordErrors["email"]}
                helperText={formResetPasswordErrors["email"]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetPasswordDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmResetPasswordDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
