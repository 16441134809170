import { createTheme } from '@mui/material/styles';
import { blue, purple, yellow } from '@mui/material/colors';

const mainTheme = createTheme({
    typography: {
      fontFamily: "Verdana",
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      fontSize: 12,
      button: {
        textTransform: 'none'
      }
    },
    palette:{
      primary: { main: blue[500] },
      secondary: yellow,
      tertiary: purple
    },    
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            borderBottom: `1px solid transparent`,
            "&:hover": {
              borderBottom: `1px solid ${blue[500]}`,
            },
          },
        },
      },
    },    
  });

export default mainTheme;