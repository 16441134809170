import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import AppTestCORS from "./AppTestCORS";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
/* Le strict mode double les requêtes envoyées au serveur API !!!
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/

root.render(<App />);
//root.render(<AppTestCORS />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
