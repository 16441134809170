import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import * as constants from "../../utils/constants";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import { fetchDataFromAPI, useErrorHandler, postData } from "../../utils/hooks";
import { TranslationContext } from "../../Components/TranslationContext";
import { Button, Link } from "@mui/material";

function PasswordReset() {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("info");
  const [timeOutDuration, setTimeOutDuration] = useState(30000);
  const { translations, updateTranslations } = useContext(TranslationContext);
  const [canReset, setCanReset] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const [data, setData] = useState([]);
  const { reset_token } = useParams();

  const API_URL_CHECK_TOKEN = `${constants.API_URL}auth/reset/check_token/${reset_token}`;
  const API_URL_RESET = `${constants.API_URL}auth/reset/reset/${reset_token}`;

  const [formValues, setFormValues] = useState({
    new_password: "",
    new_password_confirmation: "",
    reset_token: reset_token,
  });

  const { errorMessage, handleAPIError } = useErrorHandler();

  const fetchApiData = async () => {
    try {
      //check if token is still valid
      await fetchDataFromAPI(API_URL_CHECK_TOKEN, null, setData, false);
      setCanReset(true);
    } catch (error) {
      handleAPIError(error);
    }
  };

  const resetForm = () => {
    setFormValues({
      new_password: "",
      new_password_confirmation: "",
      reset_token: "",
    });
    setCanReset(false);
  };

  const handleLoginTextFieldChange = (key) => (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: event.target.value,
    }));
  };

  function isPasswordValid(password) {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/i;
    return passwordRegex.test(password);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    console.log("formValues.new_password : >" + formValues.new_password + "<");
    console.log(
      "formValues.new_password_confirmation : >" +
        formValues.new_password_confirmation +
        "<"
    );
    if (formValues.new_password !== formValues.new_password_confirmation) {
      setMessage(translations.passwords_doesnt_match);
      setMessageType("error");
      handleAPIError(translations.passwords_doesnt_match);
      setTimeOutDuration(timeOutDuration + 1);
      return;
    } else if (!isPasswordValid(formValues.new_password)) {
      setMessage(translations.password_not_match_complexity);
      setTimeOutDuration(timeOutDuration + 1);
      handleAPIError(translations.password_not_match_complexity);
      setMessageType("error");
      return;
    }

    postData(API_URL_RESET, formValues)
      .then((response) => {
        setMessage(translations.password_reset_successful);
        setMessageType("info");
        setCanLogin(true);
        resetForm();
      })
      .catch((error) => {
        handleAPIError(error);
      });
  };

  useEffect(() => {
    fetchApiData(); // initial call to get data
  }, []);

  return (
    <>
      <h1>{translations.password_reset_title}</h1>
      <ErrorMessage message={errorMessage} />
      <div className="login-wrapper">
        <div className="flex flexVertical">
          <p>{translations.password_rules}</p>
          <ul>
            <li>{translations.password_rule_1}</li>
            <li>{translations.password_rule_2}</li>
            <li>{translations.password_rule_3}</li>
            <li>{translations.password_rule_4}</li>
            <li>{translations.password_rule_5}</li>
          </ul>
          <div className="flex flexHorizontalCenter">
            <form onSubmit={handleSubmit} className="resetPasswordForm">
              <label>
                <p>{translations.password}</p>
                <input
                  id="new_password"
                  type="text"
                  value={formValues.new_password}
                  disabled={!canReset}
                  onChange={handleLoginTextFieldChange("new_password")}
                />
              </label>

              <label>
                <p>{translations.new_password_confirmation}</p>
                <input
                  id="new_password_confirmation"
                  type="text"
                  value={formValues.new_password_confirmation}
                  disabled={!canReset}
                  onChange={handleLoginTextFieldChange(
                    "new_password_confirmation"
                  )}
                />
              </label>
              <div className="flex flexHorizontalCenter">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!canReset}
                >
                  {translations.validate}
                </Button>
              </div>
            </form>
          </div>
          <br></br>
          <div className="flex flexHorizontalCenter">
            <Link
              component={RouterLink}
              to="/Login"
              visibility={canLogin ? "visible" : "hidden"}
            >
              {translations.goto_login_page}
            </Link>
          </div>
        </div>
        <Message
          message={message}
          timeout={timeOutDuration}
          type={messageType}
        />
      </div>
    </>
  );
}

export default PasswordReset;
