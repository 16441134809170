import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TranslationContext } from "../../Components/TranslationContext";

import {
  getToken,
  fetchDataFromAPI,
  updateData,
  getMessage,
  formatDate,
  getGridTexts,
  useErrorHandler,
  configureDatePicker,
} from "../../utils/hooks";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import Login from "../Login";
import validator from "validator";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as constants from "../../utils/constants";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Button,
} from "@mui/material";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MailIcon from "@material-ui/icons/Mail";

function Rents() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [error, setError] = useState(null);
  const [datePaid, setDatePaid] = useState(new Date());
  const [minDatePaid, setMinDatePaid] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [calendarDialogClassName, setCalendarDialogClassName] = useState("");
  const textFieldsRefs = useRef({});
  const [message, setMessage] = useState(null);
  const [canPay, setCanPay] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    datePaid: "",
  });
  const { translations } = useContext(TranslationContext);
  const [formErrors, setFormErrors] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const dataGridTexts = getGridTexts();

  const displayedLabels = {
    datePaid: translations.paid_on,
  };

  const fetchApiData = async () => {
    try {
      await fetchDataFromAPI(API_URL, requestOptions, setData, true);
    } catch (error) {
      handleAPIError(error);
    }
    setLoading(false);
  };

  const handleDatePaidChange = (dt) => {
    setDatePaid(dt);
    setFormValues((prevValues) => ({
      ...prevValues,
      datePaid: dt,
    }));
  };

  const handleCalendarEndClose = () => {
    setCalendarDialogClassName("calendarDialog");
  };

  /**
   * navigate to given page
   */
  const gotoPage = (page) => {
    navigate(`/${page}`);
  };

  /**
   * navigate to appartment prices
   */
  const gotoSummaryPage = () => {
    gotoPage("RentAndChargesSummary");
  };

  const handleCalendarEndOpen = () => {
    setCalendarDialogClassName("calendarDialog rentPaidDialog");
  };

  useEffect(() => {
    fetchApiData(); // read data
    configureDatePicker();
  }, []);

  /*
  Enable or disable "Paid" button depending of selection
  if selection contains at list one paid rent, then "paid" button is disabled
  */

  useEffect(() => {
    setCanPay(true);
    selectionModel.forEach((element) => {
      const itemToUpdate = data.find((item) => item.id === element);
      if (itemToUpdate.status === constants.RENT_STATUS_PAID) {
        setCanPay(false);
      }
    });
  }, [selectionModel, data]);

  const setPaid = () => {
    // Vérifier si au moins un élément sélectionné a déjà été payé
    const isAnyRentPaid = selectionModel.some((element) => {
      const itemToUpdate = data.find((item) => item.id === element);
      return itemToUpdate.status === constants.RENT_STATUS_PAID;
    });

    // Si un loyer a déjà été payé, ne rien faire
    if (isAnyRentPaid) {
      return;
    }

    if (selectionModel.length > 0) {
      const selectedData = data.filter((el) => el.id === selectionModel[0]);
      setMinDatePaid(new Date(selectedData[0].minPaidDate));
    }
    // Sinon, ouvrir la boîte de dialogue pour déclarer le loyer payé
    setOpen(true);
    setFormValues((prevValues) => ({
      ...prevValues,
      //datePaid: new Date().toLocaleDateString(),
      datePaid: new Date(),
    }));
  };

  const API_URL = `${constants.API_URL}rent`;

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
    resetForm();
  };

  /**
   * Update selected elements and close confirmation dialog
   */
  const handleConfirmDialog = () => {
    if (validateForm()) {
      selectionModel.forEach((element) => {
        const selectedData = data.filter((el) => el.id === element);
        if (selectedData.length > 0) {
          const dataToUpdate = { ...selectedData[0] }; //create an object copy
          dataToUpdate.user_id = localStorage.getItem("userId");
          dataToUpdate.datePaid = formValues.datePaid;
          dataToUpdate.status = constants.RENT_STATUS_PAID;
          dataToUpdate.timeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;
          updateData(API_URL, dataToUpdate.id, dataToUpdate, getToken())
            .then((response) => {
              fetchApiData();
            })
            .then(() => {
              setMessage(translations.update_done);
            })
            .catch((error) => {
              handleAPIError(error);
            })
            .finally(() => {
              setCanPay(true);
            });
        }
      });
      resetForm();
      setOpen(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      datePaid: "",
    };

    //All fields are mandatory
    for (const [key, value] of Object.entries(formValues)) {
      if (key !== "datePaid") {
        if (value == null || validator.isEmpty(value)) {
          isValid = false;
          errors[key] = translations.required_field.replace(
            "%s",
            displayedLabels[key]
          );
        }
      }
    }
    setFormErrors(errors);
    return isValid;
  };

  const resetForm = () => {
    setFormValues({
      datePaid: "",
    });
    setDatePaid(new Date());
    setFormErrors({});
    textFieldsRefs.current = {};
  };

  const getRowClassName = (params) => {
    if (params.row.status === "UNPAID") {
      // Si la valeur de la colonne à comparer correspond à la valeur définie
      return "datagridRedLine"; // Appliquer la classe CSS pour la mise en forme en rouge
    }
    return ""; // Aucune classe CSS personnalisée
  };

  const columns = [
    {
      field: "status",
      headerName: "",
      //flex: 1,
      width: 40,
      renderCell: (params) =>
        params.value === "PAID" ? <DoneIcon /> : <AccessTimeIcon />,
    },
    {
      field: "appartment.name",
      headerName: translations.rent_property,
      flex: 3,
      valueGetter: (params) => params.row.contract.appartment.name,
      //headerClassName: "defaultFont",
    },
    {
      field: "tenant.name",
      headerName: translations.tenant,
      flex: 3,
      valueGetter: (params) => params.row.contract.tenant.name,
      //headerClassName: "defaultFont",
    },
    {
      field: "price",
      headerName: translations.amount,
      flex: 1,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.price + " " + params.row.ccy_symbol,
    },
    {
      field: "charges",
      headerName: translations.charges,
      flex: 1,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.row.charges + " " + params.row.ccy_symbol,
    },
    {
      field: "date",
      headerName: translations.date,
      valueFormatter: formatDate,
      flex: 1.3,
      //headerClassName: "defaultFont",
    },
    {
      field: "datePaid",
      headerName: translations.paid_on,
      valueFormatter: formatDate,
      flex: 1.3,
      //headerClassName: "defaultFont",
    },
    {
      field: "receipt",
      headerName: translations.receipt,
      //headerClassName: "defaultFont",
      flex: 1,
      renderCell: (params) => {
        const getReceiptPDF = (e) => {
          e.stopPropagation();
          const receipt = params.row.receipt;
          const token = getToken();
          fetch(receipt, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                // Convertir la réponse en blob
                return response.blob();
              } else {
                // Gérer les erreurs ici
                return null;
              }
            })
            .then((blob) => {
              if (blob) {
                // Créer un objet URL à partir du blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Créer un lien <a> pour le téléchargement
                const a = document.createElement("a");
                a.href = blobUrl;
                const fileName =
                  "r_" +
                  params.row.date +
                  "_" +
                  params.row.contract.tenant.name +
                  "_" +
                  params.row.contract.appartment.name +
                  ".pdf";
                a.download = fileName; // downloaded file name
                a.style.display = "none";

                // Ajouter le lien <a> à la page
                document.body.appendChild(a);

                // Déclencher un clic sur le lien pour lancer le téléchargement
                a.click();

                // Supprimer le lien <a> de la page
                document.body.removeChild(a);

                // Révoquer l'URL de l'objet blob après le téléchargement
                window.URL.revokeObjectURL(blobUrl);
              }
            })
            .catch((error) => {
              // Gérer les erreurs réseau ici
              console.error("Erreur réseau :", error);
            });
        };

        if (params.row.status === constants.RENT_STATUS_PAID) {
          return (
            <>
              <Button
                color="primary"
                sx={{
                  color: "primary.main",
                }}
                aria-label={translations.receipt}
                onClick={getReceiptPDF}
              >
                <PictureAsPdfIcon />
              </Button>
              {/* <Button
                style={{ color: "yellow" }}
                aria-label="Envoi par courriel"
                onClick={sendEmail}
              >
                <MailIcon></MailIcon>
              </Button> */}
            </>
          );
        }
      },
    },
    {
      field: "due_notice",
      headerName: translations.due_notices_menu,
      //headerClassName: "defaultFont",
      flex: 1,
      renderCell: (params) => {
        const getDueNoticePDF = (e) => {
          e.stopPropagation();
          const due_notice = params.row.dueNotice;
          const token = getToken();
          fetch(due_notice, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                // Convertir la réponse en blob
                return response.blob();
              } else {
                // Gérer les erreurs ici
                return null;
              }
            })
            .then((blob) => {
              if (blob) {
                // Créer un objet URL à partir du blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Créer un lien <a> pour le téléchargement
                const a = document.createElement("a");
                a.href = blobUrl;
                const fileName =
                  "d_" +
                  params.row.date +
                  "_" +
                  params.row.contract.tenant.name +
                  "_" +
                  params.row.contract.appartment.name +
                  ".pdf";
                a.download = fileName; // downloaded file name
                a.style.display = "none";

                // Ajouter le lien <a> à la page
                document.body.appendChild(a);

                // Déclencher un clic sur le lien pour lancer le téléchargement
                a.click();

                // Supprimer le lien <a> de la page
                document.body.removeChild(a);

                // Révoquer l'URL de l'objet blob après le téléchargement
                window.URL.revokeObjectURL(blobUrl);
              }
            })
            .catch((error) => {
              // Gérer les erreurs réseau ici
              console.error("Erreur réseau :", error);
            });
        };

        return (
          <>
            <Button
              color="primary"
              sx={{
                color: "primary.main",
              }}
              aria-label={translations.due_notices_menu}
              onClick={getDueNoticePDF}
            >
              <PictureAsPdfIcon />
            </Button>
          </>
        );
      },
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button
          id="setPaidButton"
          onClick={setPaid}
          variant="outlined"
          color="primary"
          disabled={!canPay}
          title={translations.declare_rent_paid}
        >
          {translations.paid}
        </Button>
        <Button
          id="summaryButton"
          onClick={gotoSummaryPage}
          variant="outlined"
          color="primary"
        >
          {translations.annual_summary}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div style={{ width: "100%" }}>
      <h1>{translations.rents}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p className="error">{getMessage(error)}</p>
        )
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              getRowClassName={getRowClassName}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data}
              getRowId={(row) => row.id}
              columns={columns}
              editMode="cell"
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 50]}
              slots={{ toolbar: CustomToolbar }}
              onRowSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{translations.declare_rent_paid}</DialogTitle>
        <DialogContent className={calendarDialogClassName}>
          <Grid container spacing={2} direction="column">
            <Grid item key={"datePaid"}>
              <InputLabel id="select-label-dateTo">
                {translations.datePaid}
              </InputLabel>
              <div onKeyDown={(e) => e.preventDefault()}>
                <DatePicker
                  showIcon
                  selected={datePaid}
                  minDate={minDatePaid}
                  onChange={(date) => handleDatePaidChange(date)}
                  locale={localStorage.getItem("userLanguage").substring(0, 2)}
                  className="datePicker"
                  dateFormat="P"
                  onCalendarClose={handleCalendarEndClose}
                  onCalendarOpen={handleCalendarEndOpen}
                />
              </div>
              <Grid item key="wmessage">
                <div className="warningDialogMessage">
                  {translations.action_cant_be_undone}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleConfirmDialog}>{translations.confirm}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Rents;
