import { useState, useEffect, useRef, useContext } from "react";
import {
  getToken,
  formatDate,
  postData,
  fetchDataFromAPI,
  updateData,
  deleteData,
  convertNumericAttributesToString,
  getGridTexts,
  removeNonLettersCharacter,
  useErrorHandler,
  isValidZipCode,
  configureDatePicker,
} from "../../../utils/hooks";
import Message from "../../../Components/Message";
import ErrorMessage from "../../../Components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import Login from "../../Login";
import validator from "validator";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import * as constants from "../../../utils/constants";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LanguageSelect from "../../../Components/LanguageSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DoneIcon from "@mui/icons-material/Done";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { TranslationContext } from "../../../Components/TranslationContext";

function Users() {
  const navigate = useNavigate();
  const { errorMessage, handleAPIError } = useErrorHandler();
  let [token, setToken] = useState();
  const [languages, setLanguages] = useState([]);
  const [validUntil, setValidUntil] = useState(new Date());
  let [dialogMode, setDialogMode] = useState("create");
  const [calendarDialogClassName, setCalendarDialogClassName] = useState("");
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const textFieldsRefs = useRef({});
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [message, setMessage] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const { translations } = useContext(TranslationContext);
  const [formValues, setFormValues] = useState({
    username: "",
    first_name: "",
    last_name: "",
    enabled: "",
    street: "",
    zipcode: "",
    city: "",
    valid_until: new Date(),
    lang: "",
  });

  const dataGridTexts = getGridTexts();

  const displayedLabels = {
    username: translations.email,
    first_name: translations.first_name,
    last_name: translations.last_name,
    enabled: translations.enabled,
    street: translations.street,
    zipcode: translations.zipcode,
    city: translations.city,
    valid_until: translations.subscription_ending,
    lang: translations.language_display,
  };

  const [formErrors, setFormErrors] = useState({
    username: "",
    first_name: "",
    last_name: "",
    enabled: "",
    street: "",
    zipcode: "",
    city: "",
    valid_until: "",
    lang: "",
  });

  const [pageSize, setPageSize] = useState(20);

  const fetchApiData = async () => {
    try {
      //read properties
      await fetchDataFromAPI(API_URL, requestOptions, setData, true);
      //read available countries
      fetchDataFromAPI(
        API_URL_COUNTRIES,
        requestOptions,
        setAllCountries,
        false
      );
    } catch (error) {
      handleAPIError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("userAdmin") === "true") {
      fetchApiData(); // Appel initial pour récupérer les données
      fetchLanguages();
      configureDatePicker();
    } else {
      navigate("/NotFound");
    }
  }, []);

  const resetForm = () => {
    setFormValues({
      username: "",
      first_name: "",
      last_name: "",
      enabled: "",
      street: "",
      zipcode: "",
      city: "",
      valid_until: "",
      lang: "",
    });
    setFormErrors({});
    textFieldsRefs.current = {};
  };

  const handleSelectionChange = (newSelection) => {
    if (newSelection !== null && newSelection.length > 0) {
      setSelectionModel(newSelection);
    }
  };

  const handleEnabledCheckboxChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      enabled: event.target.checked,
    }));
  };

  /**
   * Open dialog for update
   */
  const openDialogUpdate = () => {
    if (selectionModel.length > 0) {
      const selectedItem = data.find((item) => item.id === selectionModel[0]);
      let convertedObj = convertNumericAttributesToString(selectedItem);
      delete convertedObj.properties;
      delete convertedObj.contracts;
      delete convertedObj.last_connection;
      convertedObj.valid_until = new Date(convertedObj.valid_until);
      setFormValues(convertedObj);
      setDialogMode("edit");
      setOpen(true);
    }
  };

  /**
   * Open dialog for delete confirmation
   */
  const openDialogDelete = () => {
    if (selectionModel.length > 0) {
      setOpenDelete(true);
    }
  };

  const handleTextFieldChange = (key) => (event) => {
    let updatedValue = event.target.value;

    //Allow only numeric values for stair
    if (key === "stair") {
      updatedValue = updatedValue.replace(/\D/g, "");
    } else if (key === "name" || key === "city") {
      updatedValue = removeNonLettersCharacter(updatedValue, false);
    } else if (key === "street") {
      updatedValue = removeNonLettersCharacter(updatedValue, true);
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: updatedValue,
    }));
  };

  /**
   * Close create/edit dialog
   */
  const handleCloseDialog = () => {
    setOpen(false);
    resetForm();
  };

  /**
   * Close confirmation delete dialog
   */
  const handleCloseDeleteDialog = () => {
    setOpenDelete(false);
  };

  /**
   * Delete selected elements and close confirmation dialog
   */
  const handleConfirmDeleteDialog = () => {
    //TODO: Prévoir une confirmation avant de supprimer
    selectionModel.forEach((element) => {
      deleteData(API_URL, element, getToken())
        .then((response) => {
          fetchApiData();
        })
        .then(() => {
          setMessage(translations.deletion_done);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    });

    setOpenDelete(false);
  };

  const API_URL = `${constants.API_URL}users`;
  const API_URL_UPDATE_USER = `${constants.API_URL}user`;
  const API_URL_COUNTRIES = `${constants.API_URL}country`;
  const API_URL_LANGUAGES = `${constants.API_URL}languages`;

  const handleValidUntilDateChange = (dt) => {
    setValidUntil(dt);
    setFormValues((prevValues) => ({
      ...prevValues,
      valid_until: dt,
    }));
  };

  const handleCalendarEditClose = () => {
    setCalendarDialogClassName("calendarDialog");
  };

  const handleCalendarEditOpen = () => {
    setCalendarDialogClassName("calendarDialog priceEditDialog");
  };

  /**
   * Function called when user select a country on create/update
   * @param {event} event
   */
  const handleSelectCountry = (event) => {
    const selectedCountry = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      country: selectedCountry,
    }));
  };

  const handleSaveDialog = () => {
    setMessage("");
    if (validateForm()) {
      formValues.user_id = localStorage.getItem("userId");
      if (dialogMode === "edit") {
        updateData(API_URL_UPDATE_USER, formValues.id, formValues, getToken())
          .then((response) => {
            fetchApiData();
          })
          .then(() => {
            setMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      } else {
        postData(API_URL_UPDATE_USER, formValues, getToken())
          .then((response) => {
            fetchApiData();
          })
          .then(() => {
            setMessage(translations.update_done);
          })
          .catch((error) => {
            handleAPIError(error);
          });
      }
      resetForm();
      setOpen(false);
    }
  };

  const fetchLanguages = () => {
    fetchDataFromAPI(
      API_URL_LANGUAGES,
      requestOptions,
      setLanguages,
      false
    ).catch((error) => handleAPIError(error));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      username: "",
      first_name: "",
      last_name: "",
      enabled: "",
      street: "",
      zipcode: "",
      city: "",
      valid_until: "",
      lang: "",
    };

    /**
     * All fields are mandatory
     */
    for (const [key, value] of Object.entries(formValues)) {
      isValid = true;
      if (key !== "valid_until" && key !== "token") {
        if (value == null || validator.isEmpty(value)) {
          isValid = false;
          errors[key] = translations.required_field.replace(
            "%s",
            displayedLabels[key]
          );
        } else if (!isValidZipCode(formValues.country, formValues.zipcode)) {
          errors["zipcode"] = translations.invalid_zipcode;
          isValid = false;
        }
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  /**
   * navigate to given page
   */
  const gotoPage = (page) => {
    if (selectionModel.length > 0) {
      navigate(`/${page}/${selectionModel[0]}`);
    }
  };

  /**
   * navigate to appartment prices
   */
  const gotoAttemptsAccessPage = () => {
    gotoPage("AdminAccessAttempts");
  };

  /*
  const getRowClassName = (params) => {
    return "defaultFont";
  };
  */
  const columns = [
    {
      field: "username",
      headerName: translations.email,
      flex: 2,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "first_name",
      headerName: translations.first_name,
      //width: "400",
      flex: 2,
      editable: true,
      align: "left",
      headerAlign: "left",
      //headerClassName: "defaultFont",
    },
    {
      field: "last_name",
      headerName: translations.last_name,
      flex: 2,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "valid_until",
      headerName: translations.valid_until,
      flex: 1,
      valueFormatter: formatDate,
      editable: true,
      //headerClassName: "defaultFont",
    },
    {
      field: "last_connection",
      headerName: translations.last_seen,
      flex: 1,
      //valueFormatter: formatDate,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{formatDate(params)}</span>
        </Tooltip>
      ),
      editable: false,
    },
    {
      field: "contracts",
      headerName: translations.contracts,
      flex: 1,
      editable: false,
    },
    {
      field: "properties",
      headerName: translations.rent_properties,
      flex: 1,
      editable: false,
    },
    {
      field: "enabled",
      headerName: translations.enabled,
      flex: 1,
      renderCell: (params) =>
        params.value ? <DoneIcon /> : <DoDisturbOnIcon />,

      editable: true,
      //headerClassName: "defaultFont",
    },
  ];
  const auth = `Bearer ${getToken()}`;
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="toolbarContainer">
        <Button
          onClick={openDialogUpdate}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.edit}
        </Button>
        <Button
          onClick={openDialogDelete}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.delete}
        </Button>
        <Button
          onClick={gotoAttemptsAccessPage}
          variant="outlined"
          color="primary"
          disabled={selectionModel.length === 0}
        >
          {translations.access_attempts}
        </Button>
      </GridToolbarContainer>
    );
  };

  if (!getToken() || getToken() === null) {
    return <Login setToken={setToken} />;
  }
  return (
    <div>
      <h1>{translations.users}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      {loading ? (
        <p>{translations.loading}</p>
      ) : error ? (
        error.response && error.response.status === 401 ? (
          <Login setToken={setToken} />
        ) : (
          <p>Error: {error.message}</p>
        )
      ) : (
        <div className="flex flexHorizontalCenter">
          <div className="dataGridContainer">
            <DataGrid
              sx={{
                color: "primary.main",
              }}
              localeText={
                dataGridTexts.components.MuiDataGrid.defaultProps.localeText
              }
              rows={data}
              getRowId={(row) => row.id}
              columns={columns}
              editMode="cell"
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 50]}
              slots={{ toolbar: CustomToolbar }}
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
            />
          </div>
        </div>
      )}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogMode === "edit"
            ? translations.update_property
            : translations.add_property}
        </DialogTitle>
        <DialogContent className={calendarDialogClassName}>
          <br></br>
          <Grid container spacing={2} direction="column">
            {Object.entries(formValues).map(([key, value]) => {
              // Vérifier si la clé est présente dans la liste des clés à inclure
              if (
                key !== "id" &&
                key !== "password" &&
                key !== "reset_token" &&
                key !== "reset_token_expiration" &&
                key !== "token" &&
                key !== "token_expiration" &&
                key !== "singular" &&
                key !== "properties" &&
                key !== "contracts" &&
                key !== "last_connection"
              ) {
                if (key === "country") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-country">
                        {translations.country}
                      </InputLabel>
                      <Select
                        labelId="select-label-country"
                        id="select-country"
                        value={formValues.country}
                        label={translations.country}
                        onChange={handleSelectCountry}
                        style={{ width: "100%" }}
                      >
                        {allCountries.map((country, index) => (
                          <MenuItem key={`el_${country}`} value={country}>
                            {translations["country_" + country.toLowerCase()]}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  );
                } else if (key === "valid_until") {
                  return (
                    <Grid item key={key}>
                      <InputLabel id="select-label-valid-until">
                        {translations.subscription_ending}
                      </InputLabel>
                      <div onKeyDown={(e) => e.preventDefault()}>
                        <DatePicker
                          showIcon
                          selected={formValues[key]}
                          onChange={(date) => handleValidUntilDateChange(date)}
                          locale={localStorage
                            .getItem("userLanguage")
                            .substring(0, 2)}
                          className="datePicker"
                          dateFormat="P"
                          onCalendarClose={handleCalendarEditClose}
                          onCalendarOpen={handleCalendarEditOpen}
                        />
                      </div>
                    </Grid>
                  );
                } else if (key === "lang") {
                  return (
                    <Grid item key={key}>
                      <LanguageSelect
                        id={key}
                        value={formValues[key]}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        helperText={formErrors[key]}
                        label={displayedLabels[key]}
                        languages={languages}
                        translations={translations}
                      />
                    </Grid>
                  );
                } else if (key === "enabled") {
                  return (
                    <Grid item key={key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formValues.enabled === "1"}
                            onChange={handleEnabledCheckboxChange}
                          />
                        }
                        label={translations.enabled}
                      />
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item key={key}>
                      <TextField
                        label={displayedLabels[key]}
                        inputRef={(ref) => (textFieldsRefs.current[key] = ref)}
                        value={value}
                        onChange={handleTextFieldChange(key)}
                        error={!!formErrors[key]}
                        helperText={formErrors[key]}
                      />
                    </Grid>
                  );
                }
              }
              return null;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translations.cancel}</Button>
          <Button onClick={handleSaveDialog}>{translations.save}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{translations.deletion_confirmation_title}</DialogTitle>
        <DialogContent>{translations.deletion_confirmation}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>
            {translations.cancel}
          </Button>
          <Button onClick={handleConfirmDeleteDialog}>
            {translations.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Users;
