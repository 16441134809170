import React, { useState, useEffect } from "react";

const Message = ({ message, timeout = 10000, timestamp = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (message) {
      setIsVisible(true);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, timeout);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [message, timeout, timestamp]);

  return isVisible ? (
    <div className={"fade-animation info-message"}>
      <span>{message}</span>
    </div>
  ) : null;
};

export default Message;
