import logo from "../../Images/logo_100x100.png";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EuroIcon from "@mui/icons-material/Euro";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
//import Menu from "@mui/material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useContext } from "react";
import { TranslationContext } from "../../Components/TranslationContext";
import { formatDate, translate } from "../../utils/hooks";
import MuiLink from "@mui/material/Link";
import {
  Grow,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

function Banner() {
  const { translations, wizard } = useContext(TranslationContext);
  const { differenceInDays } = require("date-fns");
  const endValidity = localStorage.getItem("valid_until");
  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("userAdmin") === "true";
  const navigate = useNavigate();
  let msgEndValidity = "";
  if (endValidity !== null && endValidity.length > 0) {
    const endValidityDate = new Date(endValidity);
    const difference = differenceInDays(endValidityDate, new Date());
    if (difference <= 30) {
      msgEndValidity =
        translations.subscription_ending_warning +
        formatDate({ value: endValidityDate });
    }
  }

  const wizardPageToGo = [
    "",
    "User",
    "Appartments",
    "Tenants",
    "Contracts",
    "Contracts",
    "Rents",
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const gotoRecoverableCharges = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    navigate("/RecoverableCharges");
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (wizard < wizardPageToGo.length) {
      navigate("/" + wizardPageToGo[wizard]);
    }
  }, [wizard]);

  if (isAdmin) {
    return (
      <>
        <div className="bannerContainer flex flexHorizontal">
          <div id="logoPart" className="flex flexHorizontal flexVerticalCenter">
            <img src={logo} className="bannerImageLogo" alt="logo" />
            <div className="bannerTextLogo">{translations.app_title}</div>
          </div>
          <nav className="bannerContainer">
            <MuiLink
              component={Link}
              to="/AdminUsers"
              className="bannerMenuElt"
            >
              <GroupIcon></GroupIcon>&nbsp;
              {translations.users}
            </MuiLink>
            <MuiLink component={Link} to="/Logout" className="bannerMenuElt">
              <LogoutIcon></LogoutIcon>&nbsp;{translations.logout}
            </MuiLink>
          </nav>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="bannerContainer flex flexHorizontal">
          <div id="logoPart" className="flex flexHorizontal flexVerticalCenter">
            <img src={logo} className="bannerImageLogo" alt="logo" />
            <div className="bannerTextLogo">{translations.app_title}</div>
          </div>
          {token && translations && (
            <nav className="bannerContainer">
              {wizard > 5 && (
                <MuiLink component={Link} to="/Rents" className="bannerMenuElt">
                  <EuroIcon></EuroIcon>&nbsp;
                  {translations.rents}
                </MuiLink>
              )}
              {wizard > 1 && (
                <MuiLink
                  component={Link}
                  to="/Appartments"
                  className="bannerMenuElt"
                >
                  <ApartmentIcon></ApartmentIcon>&nbsp;
                  {translations.rent_properties}
                </MuiLink>
              )}
              {wizard > 2 && (
                <MuiLink
                  component={Link}
                  to="/Tenants"
                  className="bannerMenuElt"
                >
                  <PersonIcon></PersonIcon>&nbsp;{translations.tenants}
                </MuiLink>
              )}
              {wizard > 3 && (
                <MuiLink
                  component={Link}
                  to="/Contracts"
                  className="bannerMenuElt"
                >
                  <DescriptionIcon></DescriptionIcon>&nbsp;
                  {translations.contracts}
                </MuiLink>
              )}
              {wizard > 0 && (
                <>
                  <MuiLink
                    component={Link}
                    to="/User"
                    className="bannerMenuElt"
                  >
                    <AccountBoxIcon></AccountBoxIcon>&nbsp;
                    {translations.my_account}
                  </MuiLink>
                  <div className="bannerMenuElt">
                    <Button
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={open ? "composition-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <SettingsIcon />
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem
                                  onClick={gotoRecoverableCharges}
                                  className="bannerSubmenuElt"
                                >
                                  <ListItemIcon>
                                    <DescriptionIcon></DescriptionIcon>
                                  </ListItemIcon>
                                  <ListItemText>
                                    {translations.recoverable_charges}
                                  </ListItemText>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  <MuiLink
                    component={Link}
                    to="/Logout"
                    className="bannerMenuElt"
                  >
                    <LogoutIcon></LogoutIcon>&nbsp;{translations.logout}
                  </MuiLink>
                </>
              )}
            </nav>
          )}
        </div>
        {msgEndValidity && (
          <div className="endValidityWarning">{msgEndValidity}</div>
        )}
        {token && wizard < 6 && (
          <div className="wizard info-message flex flexHorizontal">
            <div>
              {wizard === 1 && <Filter1Icon></Filter1Icon>}
              {wizard === 2 && <Filter2Icon></Filter2Icon>}
              {wizard === 3 && <Filter3Icon></Filter3Icon>}
              {wizard === 4 && <Filter4Icon></Filter4Icon>}
              {wizard === 5 && <Filter4Icon></Filter4Icon>}
            </div>
            <div>{translate(translations, "wizard_step_" + wizard)}</div>
          </div>
        )}
      </>
    );
  }
}
export default Banner;
