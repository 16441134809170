import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchTranslations, useErrorHandler } from "../../utils/hooks";
import ErrorMessage from "../ErrorMessage";
export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [wizard, setWizard] = useState();
  const { errorMessage, handleAPIError } = useErrorHandler();
  const updateTranslations = (newTranslations) => {
    setTranslations(newTranslations);
  };

  useEffect(() => {
    async function loadTranslations() {
      try {
        const userLanguage =
          localStorage.getItem("userLanguage") !== null
            ? localStorage.getItem("userLanguage")
            : navigator.language || navigator.userLanguage;
        const translationsData = await fetchTranslations(userLanguage);
        setTranslations(translationsData);
      } catch (error) {
        console.error("Failed to load translations:", error);
        handleAPIError(error);
      }
    }
    loadTranslations();
    console.log("useEffect de TranslationsContext");
  }, []);

  return (
    <TranslationContext.Provider
      value={{
        translations,
        updateTranslations,
        wizard,
        setWizard,
      }}
    >
      <ErrorMessage message={errorMessage} />
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslations = () => {
  return useContext(TranslationContext);
};
