import { useContext } from "react";
import { TranslationContext } from "../TranslationContext";

function Footer() {
  const { translations } = useContext(TranslationContext);

  return (
    <>
      <div className="footer">
        <div>{translations.footer_left}</div>
        <a href="/version.txt" target="_blank" rel="noopener noreferrer">
          v 0.9.2
        </a>
      </div>
    </>
  );
}
export default Footer;
